import React from 'react';
import {Layout} from 'components/pageLayouts';
import styles from './styles.module.scss';

const About = () => (
	<Layout>
		<h2 className={styles.title}>About Me</h2>
		<p>
			I am a 24 year-old person with a variety of interests. This site is
			set in Vulf Sans.
		</p>
	</Layout>
);

export default About;
